/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import ploneCollectivevoltoSentry from '@plone-collective/volto-sentry';
import voltoMultilingualWidget from 'volto-multilingual-widget';
import voltoRssBlock from 'volto-rss-block';
import voltoVenue from 'volto-venue';
import voltoSubblocks from 'volto-subblocks';
import voltoDropdownmenu from 'volto-dropdownmenu';
import voltoSubsites from 'volto-subsites';
import voltoSocialSettings from 'volto-social-settings';
import voltoEditablefooter from 'volto-editablefooter';
import voltoBlocksWidget from 'volto-blocks-widget';
import voltoGoogleAnalytics from 'volto-google-analytics';
import voltoSecondarymenu from 'volto-secondarymenu';
import voltoFormBlock from 'volto-form-block';
import voltoSubfooter from 'volto-subfooter';
import voltoCustomerSatisfaction from 'volto-customer-satisfaction';
import voltoGdprPrivacy from 'volto-gdpr-privacy';
import eeacmsvoltoMatomo from '@eeacms/volto-matomo';
import redturtlevoltoNewsletter from '@redturtle/volto-newsletter';

const addonsInfo = {};
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [ploneCollectivevoltoSentry, voltoMultilingualWidget, voltoRssBlock, voltoVenue, voltoSubblocks, voltoDropdownmenu, voltoSubsites, voltoSocialSettings, voltoEditablefooter, voltoBlocksWidget, voltoGoogleAnalytics, voltoSecondarymenu, voltoFormBlock, voltoSubfooter, voltoCustomerSatisfaction, voltoGdprPrivacy, eeacmsvoltoMatomo, redturtlevoltoNewsletter];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
