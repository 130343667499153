/* eslint-disable import/no-unresolved */
import React from 'react';
import logoArtEr from './logos/logo_art-er.png?width=334';
import logoAssemblea from './logos/logo_assemblea_legislativa.png?width=496';
import logoED from './logos/logo_europe_direct.png?width=292';
import logoRegione from './logos/logo_regione.png?width=548';
/**
 * FooterLogosExtra component class.
 * @class FooterLogosExtra
 * @extends Component
 */
const FooterLogosExtras = () => {
  return (
    <div className="footer-logos-extra">
      <img
        src={logoAssemblea}
        alt="Logo Assemblea Legislativa"
        width="248px"
        height="56.69px"
      />
      <img
        src={logoRegione}
        alt="Logo Regione Emilia-Romagna"
        width="274px"
        height="39.8px"
      />
      <img src={logoArtEr} alt="Logo Art-Er" width="167px" height="64.09px" />
      <img src={logoED} alt="LogoEurope Direct" width="256.5px" height="60px" />
    </div>
  );
};

export default FooterLogosExtras;
