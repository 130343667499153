/**
 * Add your config changes here.
 * @module config
 */

import applyItaliaConfig from 'design-comuni-plone-theme/config/italiaConfig';
import '@plone/volto/config';

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  config.settings = {
    ...config.settings,
    // italiaThemeViewsConfig: {
    //   ...config.settings.italiaThemeViewsConfig,
    //   imagePosition: 'afterHeader', // possible values: afterHeader, documentBody
    // },
    siteProperties: {
      /*
        Per tutte le props di siteProperties, può essere definito un oggetto per i siti multilingua, strutturato in questo modo:
        {'default': _valore_della_prop, 'it': _valore_della_prop,...}
        Se l'oggetto non è strutturato in questo modo, viene usato l'oggetto stesso, altrimenti viene preso il valore della corrispondente alla prop della lingua corrente o alla prop 'default' se esiste.
      */
      ...config.settings.siteProperties,
      siteTitle: "L'Europa in Emilia-Romagna",
      siteSubtitle: '',
      parentSiteTitle: 'Assemblea legislativa Emilia-Romagna',
      parentSiteURL: 'https://www.assemblea.emr.it/',
      subsiteParentSiteTitle: "L'Europa in Emilia-Romagna",
      // enableCustomerSatisfaction: false, // false per disabilitare
      // enableCustomerSatisfactionCaptcha: false,
      // enableVoltoFormBlockCaptcha: false,
      arLoginUrl: '/area-personale',
      arLogoutUrl: '/logout',
      headerslimTertiaryMenu: {
        it: [{ title: 'Contatti', url: '/contatti' }],
      },
    },
  };

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.text = {
    it: {
      title: 'Usiamo i cookie',
      description:
        "Questo sito utilizza <strong>cookies tecnici</strong> e talvolta anche <strong>cookies di terze parti</strong> che potrebbero raccogliere i tuoi dati a fini di profilazione; questi ultimi possono essere installati <strong>solo con il tuo consenso esplicito</strong>, tramite il pulsante <strong>Accetta tutto</strong>, oppure modificando le tue preferenze. Chiudendo il banner (con la X in alto a destra) verranno utilizzati solo i cookies tecnici.<br><br>Per maggiori informazioni e per modificare le tue preferenze, puoi consultare la nostra <a href='/cookies-policy'>Cookie Policy</a>.",
    },
  };

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical.choices = [
    {
      config_key: 'MATOMO',
      text: {
        it: {
          title: 'Matomo',
          description:
            "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
        },
        // en: {
        //   title: 'Matomo',
        //   description:
        //     'Matomo cookies are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
        // },
        // fr: {
        //   title: 'Matomo',
        //   description:
        //     "Les cookie de Matomo sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
        // },
      },
    },
    // ...config.settings[
    //   'volto-gdpr-privacy'
    // ].defaultPanelConfig.technical.choices.filter(
    //   (f) => f.config_key !== 'GANALYTICS',
    // ),
  ];

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.profiling.choices = [
    ...config.settings[
      'volto-gdpr-privacy'
    ].defaultPanelConfig.profiling.choices.filter(
      (f) => f.config_key === 'YOUTUBE',
    ),
  ];

  // config.views = {
  //   ...config.views,
  // };
  // config.widgets = {
  //   ...config.widgets,
  // };

  // config.blocks.blocksConfig.listing = {
  //   ...config.blocks.blocksConfig.listing,
  //   variations: [
  //     ...config.blocks.blocksConfig.listing.variations,
  //     // {
  //     //   id: 'cardWithSideImageTemplate',
  //     //   isDefault: false,
  //     //   title: 'Card con immagine affiancata',
  //     //   template: CardWithSideImageTemplate,
  //     //   skeleton: CardWithSideImageTemplateSkeleton,
  //     //   schemaEnhancer: ({ schema, formData, intl }) => {
  //     //     let pos = addDefaultOptions(schema, formData, intl);
  //     //     addCardWithSideImageTemplateOptions(schema, formData, intl, pos);
  //     //     return schema;
  //     //   },
  //     // },
  //   ],
  //   listing_bg_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
  //   listing_items_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
  // };

  return config;
}
